<template>
    <div class="o-layout o-layout--medium">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <recess-button
                        :title="BUTTON_TEXT.goBack"
                        icon="back"
                        variant="variant4"
                        class="qa-go-back-button"
                        url="/portfolio"
                    />
                </div>

                <div class="col-12 d-flex justify-content-between align-items-center">
                    <title-text :title="PROVIDER.Portfolio.EdudexFeed.Title" />

                    <recess-button
                        :title="BUTTON_TEXT.howItWorks"
                        icon="info"
                        variant="variant4"
                        class="d-flex qa-edudex-dashboard-info-button"
                        @click.native.prevent="openDashboardInfoModal()"
                    />
                </div>
            </div>

            <div class="row">
                <!-- Edudex Feed Import -->
                <edudex-feed />
            </div>

            <div class="row">
                <edudex-import-list :set-selected-filters="localFilters" />
            </div>
        </div>

        <dashboard-info-pop-up-component
            v-if="isDashboardInfoModalVisible"
            :is-modal-visible="isDashboardInfoModalVisible"
            dashboard-type="edudex"
            @close="closeDashboardInfoModal"
        />
    </div>
</template>

<script>  
import { mapState } from 'vuex' 
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'

const TitleText = () => import('@/components/atoms/TitleText')
const EdudexFeed = () => import('@/components/organisms/Portfolio/Edudex/EdudexFeed')
const EdudexImportList = () => import('@/components/organisms/Portfolio/Edudex/EdudexImportList')
const DashboardInfoPopUpComponent = () => import('@/components/molecules/DashboardInfoPopUpComponent')

import filterMixin from '@/../../shared/mixins/filterMixin'

export default {
    name: 'EdudexImportPage',
    components: {
        TitleText,
        EdudexFeed,
        EdudexImportList,
        DashboardInfoPopUpComponent
    },
    mixins: [filterMixin],
    data() {
        return {
            PROVIDER,
            BUTTON_TEXT,
            isDashboardInfoModalVisible: false,
            selectedFilters: {
                providerId: localStorage.getItem('providerId')
            }
        }
    },
    computed: {
        ...mapState('providerModule', ['selectedProvider'])
    },
    watch: {
        selectedProvider: { 
            handler() {
                if (this.selectedProvider && this.selectedProvider.id !== this.selectedFilters.providerId) {
                    this.setSpecificFilter('providerId', this.selectedProvider.id)
                }
            },
            deep: true
        }
    },
    methods: {
        openDashboardInfoModal() {
            this.isDashboardInfoModalVisible = true
        },
        closeDashboardInfoModal() {
            this.isDashboardInfoModalVisible = false
        }
    }
}
</script>
